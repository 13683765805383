import { extendTheme } from "@chakra-ui/core"

const theme = extendTheme({
  fonts: {
    body: "'Noto Sans JP', sans-serif",
    heading: "'Noto Sans JP', sans-serif",
    mono: "Menlo, monospace",
  },
})

export default theme
